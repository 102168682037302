import React from 'react';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import Homepage from '~containers/homepage';
import WorkerProfile from '~containers/profile/page/worker/';
import PublicRoute from './public-route';

// const Homepage = loadable(() =>
//   import(/* webpackChunkName: "homepage", webpackPrefetch: true */ '~containers/homepage')
// );

const VerifyRequest = loadable(() =>
  import(/* webpackChunkName: "verifyRequest", webpackPrefetch: true */ '~containers/authentication/verify/request')
);
const FinalizeEmail = loadable(() =>
  import(/* webpackChunkName: "finalizeEmail", webpackPrefetch: true */ '~containers/authentication/finalize/email')
);
const FinalizeExternal = loadable(() =>
  import(
    /* webpackChunkName: "finalizeExternal", webpackPrefetch: true */ '~containers/authentication/finalize/external'
  )
);
const LoggedOut = loadable(() =>
  import(/* webpackChunkName: "logged-out", webpackPrefetch: true */ '~containers/authentication/logged-out')
);
const Logout = loadable(() =>
  import(/* webpackChunkName: "logout", webpackPrefetch: true */ '~containers/authentication/logout')
);
const AfterLogin = loadable(() =>
  import(/* webpackChunkName: "after-login", webpackPrefetch: true */ '~containers/authentication/after-login')
);
const Register = loadable(() =>
  import(/* webpackChunkName: "register", webpackPrefetch: true */ '~containers/authentication/register')
);
const Authorize = loadable(() =>
  import(/* webpackChunkName: "authorize", webpackPrefetch: true */ '~containers/authentication/authorize')
);
const CleaningWork = loadable(() =>
  import(/* webpackChunkName: "cleaning-work", webpackPrefetch: true */ '~containers/cleaning-work')
);
const PrivacyPolicy = loadable(() =>
  import(/* webpackChunkName: "privacy-policy", webpackPrefetch: true */ '~containers/privacy-policy')
);
const TermsAndConditions = loadable(() =>
  import(/* webpackChunkName: "terms-and-conditions", webpackPrefetch: true */ '~containers/terms-and-conditions')
);
const Contact = loadable(() => import(/* webpackChunkName: "contact", webpackPrefetch: true */ '~containers/contact'));
const FAQ = loadable(() => import(/* webpackChunkName: "faq", webpackPrefetch: true */ '~containers/faq'));
const ClientIntake = loadable(() =>
  import(/* webpackChunkName: "intake-client", webpackPrefetch: true */ '~containers/intake/client')
);
const WorkerIntake = loadable(() =>
  import(/* webpackChunkName: "intake-worker", webpackPrefetch: true */ '~containers/intake/worker')
);
const CustomerProfile = loadable(() =>
  import(/* webpackChunkName: "customer", webpackPrefetch: true */ '~containers/profile/page/customer/')
);
const Sitemap = loadable(() => import(/* webpackChunkName: "sitemap", webpackPrefetch: true */ '~containers/sitemap'));
const Unsubscribe = loadable(() => import(/* webpackChunkName: "unsubscribe", webpackPrefetch: true */ '~containers/unsubscribe'));
const UnsubscribeActions = loadable(() => import(/* webpackChunkName: "unsubscribe-actions", webpackPrefetch: true */ '~containers/unsubscribe/unsubscribe-actions'));
const Workers = loadable(() => import(/* webpackChunkName: "workers", webpackPrefetch: true */ '~containers/workers'));
const Customers = loadable(() => import(/* webpackChunkName: "customers", webpackPrefetch: true */ '~containers/customers'), {
  preload: true
});

const PublicRoutes = () => {
  const intl = useIntl();

  return (
    <>
      <PublicRoute exact path="/">
        <Homepage />
      </PublicRoute>
      <PublicRoute exact path="/search">
        <Homepage />
      </PublicRoute>
      <PublicRoute exact path={`/${intl.formatMessage({ id: 'routes.privacy-policy' })}`}>
        <PrivacyPolicy />
      </PublicRoute>
      <PublicRoute exact path={`/${intl.formatMessage({ id: 'routes.terms-and-conditions' })}`}>
        <TermsAndConditions />
      </PublicRoute>
      <PublicRoute exact path={`/${intl.formatMessage({ id: 'routes.faq' })}`}>
        <FAQ />
      </PublicRoute>
      <PublicRoute exact path={`/${intl.formatMessage({ id: 'routes.contact' })}`}>
        <Contact />
      </PublicRoute>
      <PublicRoute exact path={`/${intl.formatMessage({ id: 'routes.cleaning-work' })}`}>
        <CleaningWork />
      </PublicRoute>
      <PublicRoute exact path="/verify/request">
        <VerifyRequest />
      </PublicRoute>
      <PublicRoute exact path="/logged-out">
        <LoggedOut />
      </PublicRoute>
      <PublicRoute exact path="/logout">
        <Logout />
      </PublicRoute>
      <PublicRoute exact path="/after-login">
        <AfterLogin />
      </PublicRoute>
      <PublicRoute exact path="/register">
        <Register />
      </PublicRoute>
      <PublicRoute exact path="/authorize">
        <Authorize />
      </PublicRoute>
      <PublicRoute exact path="/finalize/email">
        <FinalizeEmail />
      </PublicRoute>
      <PublicRoute exact path="/finalize/external">
        <FinalizeExternal />
      </PublicRoute>
      <PublicRoute exact path="/intake/client">
        <ClientIntake />
      </PublicRoute>
      <PublicRoute exact path="/intake/worker">
        <WorkerIntake />
      </PublicRoute>
      <PublicRoute exact path="/sitemap">
        <Sitemap />
      </PublicRoute>
      <PublicRoute exact path="/unsubscribe-actions">
        <UnsubscribeActions />
      </PublicRoute>
      <PublicRoute exact path="/unsubscribe">
        <Unsubscribe />
      </PublicRoute>
      <PublicRoute exact path={'/workers'}>
        <Workers />
      </PublicRoute>
      <PublicRoute exact path={'/customers'}>
        <Customers />
      </PublicRoute>
      <PublicRoute path={`/worker/:personId`}>
        <WorkerProfile />
      </PublicRoute>
      <PublicRoute path={`/customer/:personId`}>
        <CustomerProfile />
      </PublicRoute>

    </>
  );
};

export default PublicRoutes;
