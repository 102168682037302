import React from 'react';
import cx from 'classnames';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { useStateFromProp } from '~hooks/common';
import './style.scss';

const Context = React.createContext();

const Wrapper = ({ children, forScreen = null, className, ...props }) => {
  const [isExpanded, onChange] = useStateFromProp(props.isExpanded ?? true, props.onChange);

  return (
    <Context.Provider value={[onChange, isExpanded, forScreen]}>
      <div
        className={cx('c-collapsible', {
          'c-collapsible--expanded': isExpanded,
          [`c-collapsible--for-${forScreen}`]: forScreen,
          [className]: !!className,
        })}
      >
        {children}
      </div>
    </Context.Provider>
  );
};

const Header = ({ children, className }) => {
  const [onChange, isExpanded] = React.useContext(Context);

  return (
    <button
      type="button"
      className={cx('c-collapsible__header', {
        [className]: !!className,
      })}
      onClick={() => onChange(!isExpanded)}
    >
      {children}
      {isExpanded ? (
        <FiChevronUp className="c-collapsible__icon c-collapsible__icon--up" />
      ) : (
        <FiChevronDown className="c-collapsible__icon c-collapsible__icon--down" />
      )}
    </button>
  );
};

const Content = ({ children, className }) => {
  return (
    <div
      className={cx('c-collapsible__content', {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

const ContentCollapsed = ({ children, className }) => {
  return (
    <div
      className={cx('c-collapsible__content-collapsed', {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default {
  Wrapper,
  Header,
  Content,
  ContentCollapsed,
};
