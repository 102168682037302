import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getRelativeTime } from '~helpers/timeframes';
import { capitalize } from '~helpers/common';

import './style.scss';

const RegisteredSince = ({ registerDate, isPublished = true, isOnline = false }) => {
  const intl = useIntl();
  const [registeredDateFormatted, setRegisteredDateFormatted] = useState(null);

  useEffect(() => {
    if (!registerDate) {
      setRegisteredDateFormatted(null);
      return;
    }

    const registeredTimeV = getRelativeTime(registerDate, Math.floor(Date.now() / 1000));
    setRegisteredDateFormatted(registeredTimeV);
  }, [registerDate]);

  const formatTimeWithoutLastWord = (value, unit) => {
    // Format the relative time
    const formatted = intl.formatRelativeTime(value, unit, {
      numeric: 'always',
      style: 'long',
    });
  
    const words = formatted.split(' ');
    words.pop(); 
    return words.join(' ');
  };

  return (
    <div className="c-registered-since">
      {!registeredDateFormatted ? (
        <>-</>
      ) : (
        <>
          {capitalize(
            formatTimeWithoutLastWord(registeredDateFormatted.value, registeredDateFormatted.unit)
            // intl.formatRelativeTime(registeredDateFormatted.value, registeredDateFormatted.unit, {
            //   numeric: 'always',
            //   style: 'long',
            // })
          )}
          {/* {isOnline && (
            <>
              {' '}
              <i className="c-registered-since__online" />
              {' '}
              ({intl.formatMessage({ id: 'components.card.last-online.online', defaultMessage: 'Online' })})
            </>
          )} */}
        </>
      )}
    </div>
  );
};

export default RegisteredSince;