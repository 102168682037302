import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';

import { UserContext } from '~contexts/user';
import { stringToJSON } from '~helpers/common';

import { useOrder } from '~components/listing/useOrder';
import FiltersButton from '~components/listing/filters/button';
import Sort from '~components/listing/sort';
import ResultsCount from '~components/listing/results-count';
import { useListing } from '~components/listing/useListing';
import MapToggle from '~components/map/map-toggle/map-toggle';

import { useDefaultOperations } from '~containers/operations/default-operations';

import Filters from './filters';
import { useFilters } from './filters/useFilters';
import ResultsList from './results-list';
import ResultsMap from './results-map';
import LocationContext from '../locationContext';
import { ORDER } from './order';
import AddOfferButton from './add-offer';
import { searchCriteria } from './searchCriteria';

import '~components/listing/style.scss';
import './style.scss';

// import { SidebarCities } from '~components/footer/cities';
import { LocationSearch } from '~components/forms/location';

import BottomNav from '~components/bottom-bar/BottomNav';

const LISTING_ITEMS = 12;

const CustomersList = () => {
  const history = useHistory();
  const intl = useIntl();

  const { locationDetails, pricingDetails, isLoadingLocation, locationTouched, onChangeLocation } = useContext(LocationContext);
  const { user } = useContext(UserContext);
  const { operations: userOperations } = user;

  const { isAuthenticated } = useAuth0();

  const defaultOperations = useDefaultOperations();
  const operations = userOperations || defaultOperations;

  const [order, selectedOrder, setOrder, orderTouched] = useOrder(ORDER);
  const { filters, updateFilters, filtersTouched, areFiltersInitialized } = useFilters();

  const [delayedRender, setDelayedRender] = useState(false);
  const [isShowingMap, setIsShowingMap] = useState(false);
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);
  const [isSortVisible, setIsSortVisible] = useState(false);

  const defaultCriteria = useRef(
    searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      maxDistance: operations.maxTravelDistance,
      pagination: {
        limit: LISTING_ITEMS,
        offset: 0,
      },
      order: selectedOrder,
      filters,
    })
  ).current;

  const parseResult = (r) => {
    if (!r) {
      return null;
    }

    return {
      personId: r.id,
      firstName: r.first_name,
      profilePicture: stringToJSON(r.profile_picture),
      lastLogin: r.last_login,
      registerDate: r.registered_date,
      isOnline: r.is_online,
      personalNote: r.personal_note,
      verification: [
        {
          type: 'email',
          isVerified: (r.verification.find((item) => item.type === 'EMAIL') || {}).is_verified,
        },
        {
          type: 'sms',
          isVerified: (r.verification.find((item) => item.type === 'EMAIL') || {}).is_verified,
        },
      ],
      score: r.score,
      price: r.price,
      location: {
        locationId: r.location.place_id,
        place_id: r.location.place_id,
        city: r.location.city,
        slug: r.location.slug,
        country: r.location.country,
        latitude: r.location.latitude,
        longitude: r.location.longitude,
      },
      days: r.days,
      duration: r.duration,
      interval: r.interval,
      published: r.published,
    };
  };

  const { results, count, offset, isLoadingResults, isLoadingCount, isLoadingMore, updateListing, reorder, showMore } =
    useListing({
      criteria: defaultCriteria,
      matchRequestName: 'customers',
      countRequestName: 'customers',
      matchMockFileName: 'customers/get-customers-by-criteria',
      countMockFileName: 'customers/count-customers-by-criteria',
      itemsPerPage: LISTING_ITEMS,
      parseResult,
    });

  const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const [desktopFiltersVisible, setDesktopFiltersVisible] = useState(true);

  const toggleMap = () => {
    if (isShowingMap) {
      setIsShowingMap(false);
      setDesktopFiltersVisible(true);
      return;
    }

    if (!locationDetails || !locationDetails.locationId) {
      return;
    }

    setDelayedRender(true);
    setIsShowingMap(true);
    setDesktopFiltersVisible(false);
    const newCriteria = searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      maxDistance: operations.maxTravelDistance,
      order: selectedOrder,
      filters,
    });

    updateListing(newCriteria, !isAuthenticated);
    setTimeout(() => setDelayedRender(false), 0);
  };

  useEffect(() => {
    if (!orderTouched) {
      return;
    }

    const newCriteria = searchCriteria({
      ...defaultCriteria,
      pagination: {
        limit: LISTING_ITEMS,
        offset: 0,
      },
      location: locationDetails,
      currency: pricingDetails?.currency,
      maxDistance: operations.maxTravelDistance,
      filters,
      order: selectedOrder,
    });

    reorder(newCriteria);
  }, [selectedOrder]);

  useEffect(() => {
    if (!filtersTouched) {
      return;
    }

    const pagination = !isShowingMap
      ? {
          limit: LISTING_ITEMS,
          offset: 0,
        }
      : null;

    const newCriteria = searchCriteria({
      ...defaultCriteria,
      location: locationDetails,
      currency: pricingDetails?.currency,
      maxDistance: operations.maxTravelDistance,
      filters,
      order: selectedOrder,
      pagination,
    });

    updateListing(newCriteria);
  }, [filters]);

  useEffect(() => {
    if (
      !locationDetails ||
      !locationDetails.locationId ||
      !locationTouched ||
      isLoadingLocation ||
      !areFiltersInitialized
    ) {
      return;
    }

    // if (
    //   !locationDetails ||
    //   !locationDetails.locationId ||
    //   isLoadingLocation
    // ) {
    //   return;
    // }

    const pagination = !isShowingMap
      ? {
          limit: LISTING_ITEMS,
          offset: 0,
        }
      : null;

    const nFilters = { ...filters };
    if (filters.price === null) {
      nFilters.price = pricingDetails.minPrice;
    }

    setDelayedRender(true);
    const newCriteria = searchCriteria({
      location: locationDetails,
      currency: pricingDetails?.currency,
      maxDistance: operations.maxTravelDistance,
      pagination,
      order: selectedOrder,
      filters: nFilters,
    });

    updateListing(newCriteria, !isAuthenticated && !isLoadedOnce);
    setIsLoadedOnce(true);
    setTimeout(() => setDelayedRender(false), 0);
  }, [isLoadingLocation, areFiltersInitialized]);

  useEffect(() => {
    if (isLoadingLocation) {
      setDelayedRender(true);
    }
  }, [isLoadingLocation]);

  return (
    <>
    <BottomNav 
        onFilterClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
        onSortClick={() => {
          if (!isLoadingLocation && !isLoadingResults && !isLoadingCount && !delayedRender) {
            setIsSortVisible(!isSortVisible);
          }
        }}
        onMapClick={toggleMap}
        isMapActive={isShowingMap}
        isMapDisabled={
          !locationDetails ||
          !locationDetails.locationId ||
          isLoadingLocation ||
          isLoadingResults ||
          isLoadingCount ||
          !count
        }
        isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender}
    />
    
    <div className="c-matches__container c-matches--customer">
      <aside
        className={cx('c-page__sidebar c-page__sidebar--matches c-page__sidebar--tablet-hidden', {
          'l-hidden': !desktopFiltersVisible,
        })}
      >
        <div className="c-sidebar__title">
          {intl.formatMessage({
            id: 'containers.customers.sidebar.title',
            defaultMessage: 'Your preferences',
          })}
        </div>
        <Filters
          values={filters}
          onChange={updateFilters}
          showDesktopFilters={desktopFiltersVisible}
          showMobileFilters={mobileFiltersVisible}
          isLoading={isLoadingLocation || delayedRender}
        />
        {/* <SidebarCities displayNeighborhoods={false} /> */}
      </aside>
      <div className={cx('c-matches__list c-page__main', { 'c-matches__list--map-view': isShowingMap })}>
        <div className="srch-lst">
          <div className="srch-title">
            {intl.formatMessage({
              id: 'containers.customers.sidebar.subTitle',
              defaultMessage: 'Fill in your postcode or address to see the customers that finding workers at your address',
            })}
          </div>
          <LocationSearch
            isDataLoading={isLoadingLocation || delayedRender}
            location={locationDetails}
            placeholder={intl.formatMessage({
              id: 'containers.customers.subheader.address',
              defaultMessage: 'Address',
            })}
            name="address"
            className="c-autocomplete--focus-disabled"
            onSubmit={(loc) => {
              if (loc && loc.place_id) {
                history.push({
                  pathname: '/customers',
                  search: `?locationId=${loc.place_id}`,
                  state: {
                    location: {
                      locationId: loc.place_id,
                      address: loc.address,
                    },
                  },
                });
                onChangeLocation(loc.place_id);
              }
            }}
          />
        </div>

        <div className="c-matches__list-header">
          <div className="c-matches__list-header-left">
            <ResultsCount count={count} isLoading={isLoadingLocation || isLoadingCount || delayedRender} />
            <FiltersButton
              className={cx({ 'c-filters-button--map-visible': isShowingMap })}
              isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender}
              onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
            />
          </div>
          <div className="c-matches__list-header-right c-matches__list-header-right--customers">
            <MapToggle
              isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender}
              isShowingMap={isShowingMap}
              disabled={
                !locationDetails ||
                !locationDetails.locationId ||
                isLoadingLocation ||
                isLoadingResults ||
                isLoadingCount ||
                !count
              }
              onClick={toggleMap}
            />
            {(!isLoadingLocation && !count) || isShowingMap ? null : (
              <Sort
                order={order}
                value={selectedOrder}
                isLoading={isLoadingLocation || isLoadingResults || isLoadingCount || delayedRender}
                onChange={setOrder}
                isVisible={isSortVisible}
                onVisibleChange={setIsSortVisible}
              />
            )}
          </div>
        </div>

        {!isShowingMap ? (
          <ResultsList
            matches={results}
            count={count}
            locationId={locationDetails?.locationId}
            isLoading={isLoadingResults || isLoadingLocation || delayedRender}
            isLoadingMore={isLoadingMore}
            onShowMore={() =>
              showMore(
                searchCriteria({
                  ...defaultCriteria,
                  location: locationDetails,
                  currency: pricingDetails?.currency,
                  maxDistance: operations.maxTravelDistance,
                  filters,
                  order: selectedOrder,
                })
              )
            }
          />
        ) : (
          <ResultsMap
            matches={results}
            isLoading={isLoadingResults || isLoadingLocation || delayedRender}
            locationId={locationDetails?.locationId}
          />
        )}

        {count > 0 &&
        offset + LISTING_ITEMS >= count &&
        !isLoadingMore &&
        !isLoadingResults &&
        !isLoadingCount &&
        !isLoadingLocation &&
        !delayedRender ? (
          <AddOfferButton
            destination={`/intake/worker${
              locationDetails?.locationId ? `?locationId=${locationDetails?.locationId}` : ''
            } `}
          />
        ) : null}
      </div>
    </div>
    </>
  );
};

export default CustomersList;
