import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { renderFirstName } from '~helpers/common';
import moment from 'moment';

import VerificationBadge from '~components/verification/badge';
import Avatar from '~components/avatar';
import Stars from '~components/stars';
// import LastOnline from '~components/last-online';
import RegisteredSince from '~components/registered-since';
import Button from '~components/button';
import Favorite from '~components/favorite';
import Tooltip from '~components/tooltip';

import '../style.scss';

const WorkerCardMobile = memo(
  ({
    personId,
    className,
    score,
    price,
    address,
    experience,
    firstName,
    profilePicture,
    verification,
    lastLogin,
    registerDate,
    isOnline,
    personalNote,
    days,
    published,
    showViewButton = false,
    showCloseButton = false,
    onClose = () => {},
    showFavorite = false,
    isFavorite,
    onView = () => {},
    onFavorite = () => {},
  }) => {
    const intl = useIntl();

    const truncatedDescription = personalNote
      ? truncate(personalNote, {
          length: 130,
          separator: ' ',
        })
      : '-';

    const fName = renderFirstName(firstName);

    return (
      <div className={cx('c-card c-card__mob_list c-card--worker', { [className]: !!className })} itemType="http://schema.org/Product">
        <meta itemProp="brand" content="Juan Pescador" />
        <meta itemProp="sku" content={personId} />
        <meta itemProp="name" content={fName} />
        {profilePicture && profilePicture.large ? <link itemProp="image" href={profilePicture.large} /> : null}
        <meta
          itemProp="description"
          content={
            personalNote ||
            intl.formatMessage(
              {
                id: 'containers.profile.seo.worker.description.default',
              },
              {
                name: fName,
                city: address?.city || address?.country,
              }
            )
          }
        />
        <div className={`c-card__inner ${!published ? 'c-card__inner_not_active' : ''}`} itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <div className="c-card__header">
            <Avatar profilePicture={profilePicture} firstName={fName} className="c-card__photo" size="large" />
            {/* size="thumbnail" option is also there for smaller size picture*/}
            <div className="c-card__value c-card__value--highlight">{truncatedDescription}</div>

            {showCloseButton && (
              <button type="button" className="c-card__close" onClick={onClose}>
                <FiX />
              </button>
            )}
          </div>
          <div className="c-card__content">
            <div className="c-card__mob__name">
                <div className="c-card__name l-truncate notranslate">
                    {fName}
                </div>
                <div className="c-card__item">
                    <div className="c-card__key">
                      {intl.formatMessage({ id: 'components.card.registered-since.label', defaultMessage: 'Registered Since' })}
                      <RegisteredSince registerDate={registerDate} isPublished={published} isOnline={isOnline} />
                    </div>
                    <div className="c-card__value">
                        {/* <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} registerDate={registerDate} /> */}
                        {/* <RegisteredSince registerDate={registerDate} isPublished={published} isOnline={isOnline} /> */}
                    </div>
                </div>
            </div>
            {/* LOCATION */}
            
            {/* VIEW BUTTON */}
            {showViewButton && (
              <Button className="c-btn c-card__button" onClick={onView}>
                {intl.formatMessage({ id: 'components.card.mobile-view', defaultMessage: 'View' })}
              </Button>
            )}
          </div>
          <link itemProp="url" href={`/worker/${personId}`} />
          <meta itemProp="availability" content="https://schema.org/InStock" />
          <meta itemProp="price" content={price ? price.amount * 0.01 : null} />
          <meta itemProp="priceCurrency" content={price ? price.currency : null} />
          <meta itemProp="priceValidUntil" content={moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')} />
          {score && score.score && score.votes > 0 ? (
            <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
              <meta itemProp="reviewCount" content={score.votes} />
              <meta itemProp="ratingValue" content={score.score} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

WorkerCardMobile.propTypes = {
  personId: PropTypes.string.isRequired,
  className: PropTypes.string,
  score: PropTypes.shape({ votes: PropTypes.number, score: PropTypes.number }),
  price: PropTypes.shape({ currency: PropTypes.string, amount: PropTypes.number }),
  address: PropTypes.shape({ city: PropTypes.string, country: PropTypes.string }),
  experience: PropTypes.number,
  firstName: PropTypes.string,
  profilePicture: PropTypes.shape({ thumbnail: PropTypes.string, large: PropTypes.string }),
  verification: PropTypes.bool,
  lastLogin: PropTypes.number,
  isOnline: PropTypes.bool,
  personalNote: PropTypes.string,
  showViewButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  showFavorite: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onView: PropTypes.func,
  onFavorite: PropTypes.func,
};

WorkerCardMobile.defaultProps = {
  className: '',
  score: {},
  price: {},
  address: {},
  experience: 0,
  firstName: '',
  profilePicture: '',
  verification: false,
  lastLogin: 0,
  isOnline: false,
  personalNote: '',
  showViewButton: false,
  showCloseButton: false,
  onClose: () => {},
  showFavorite: false,
  isFavorite: false,
  onView: () => {},
  onFavorite: () => {},
};

export default WorkerCardMobile;
