import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useLocationDetails } from '~hooks/useLocationDetails';

import LocationContext from '~containers/workers/locationContext';
import WorkersList from '~containers/workers/list';

const City = ({ location }) => {
  const intl = useIntl();
  const urlLocation = useLocation();

  const { locationDetails, pricingDetails, isLoading, locationTouched, requestLocationByID } = useLocationDetails(
    location?.locationId,
    [urlLocation.pathname]
  );

  // Add effect to track location changes and manually trigger requestLocationByID
  useEffect(() => {
    if (location?.locationId) {
      requestLocationByID(location.locationId);
    }
  }, [location?.locationId]);

  useLayout({
    displayCities: false,
    displayNeighborhoods: false,
    displaySignIn: true,
    displayTopCTABar: true,
    logoToBottom: true,
    locationId: location?.locationId,
  });

  useSeo(
    intl.formatMessage(
      {
        id: 'containers.city.seo.title',
      },
      { city: location?.description }
    )
  );

  return (
    <LocationContext.Provider
      value={{
        locationDetails,
        pricingDetails,
        initialSearchLocationId: location?.locationId,
        isLoadingLocation: isLoading,
        locationTouched,
        requestLocationByID,
      }}
    >
      <section className="c-page l-container c-page--matches">
        {locationDetails ? (
          <WorkersList isCityPage={true} />
        ) : (
          <div className="c-location-intro">
            {intl.formatMessage({
              id: 'containers.workers.intro',
              defaultMessage: 'Lets get started! Please write down your address',
            })}
          </div>
        )}
      </section>
    </LocationContext.Provider>
  );
};

export default City;